<template>
  <div class="min-h-screen flex flex-column" ref="el">

    <Head :title="_title" />

    <div class="mx-5 flex flex-column flex-auto mt-2 mb-3">
      <Menubar id="navbar" class="bg-transparent border-none mb-2" ref="navbar" :class="{'hide-menu' : navEntries.length === 0}" :model="navEntries">
        <template #start>
          <img v-if="logo" alt="logo" :src="`/images/${logo}`" height="40" class="mr-5">
          <img v-else alt="logo" :src="`/images/${_user ? 'logo.svg' : 'logo.png'}`" height="40" class="mr-5">
        </template>
        <template #item="{item}">
          <Link :href="item.url"
                class="mt-2 inline-block mr-4 no-underline transition-all transition-duration-500 hover:text-900"
                :class="isCurrent(item) ? 'text-900 font-semibold': 'text-500'">
            {{item.label}}
          </Link>
        </template>
        <template #end>
          <div v-if="_user" class="flex">

            <Button class="mr-2 p-button-text p-button-rounded p-button-secondary"
                    @click="_searching = true"
                    :class="{active : _searching}"
                    icon="pi pi-search"/>

            <Search v-model="_searching" @goto=""/>

            <LinkButton v-if="Object.entries(_modules.calendar).find(([m, e]) => e === true)"
                        url="/calendar"
                        class=" mr-2 p-button-text p-button-rounded p-button-secondary"
                        :class="{active : usePage().url.value.startsWith('/calendar')}"
                        icon="pi pi-calendar"/>

            <OptionsMenu :options="_options"
                         class="inline-block"
            />
          </div>
        </template>
      </Menubar>

      <div id="content">
        <slot />
      </div>
    </div>

    <Modal />
    <Flashes />

    <ConfirmDialog />

    <div v-if="_impersonate?.can || _impersonate?.impersonating" class="fixed" style="bottom: 1rem; left: 1rem">
      <Button type="button"
              class="p-button-secondary p-button-rounded p-button-outlined p-button-sm"
              icon="pi pi-users"
              :label="_user.name"
              @click="toggleUsersMenu"
              aria-haspopup="true" />
      <Menu id="impersonate_menu" ref="users_menu" :model="_users" :popup="true" />
    </div>

  </div>
</template>

<script setup>

const logo = import.meta.env.VITE_BRAND_LOGO

import {computed, inject} from 'vue'
import {usePage, Head} from '@inertiajs/inertia-vue3'
import Flashes from '@/Partials/Flashes.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import Menubar from 'primevue/menubar'
import {Modal} from 'momentum-modal'
import Button from 'primevue/button'
import {Inertia} from '@inertiajs/inertia'
import OptionsMenu from '@Components/OptionsMenu.vue'
import Menu from 'primevue/menu'
import Search from '@Partials/Search.vue'
import LinkButton from '@Components/LinkButton.vue'

const demo = inject('demo')

const _user = computed(() => usePage().props.value.auth.user)
const _modules = computed(() => usePage().props.value.modules)
const _impersonate = computed(() => usePage().props.value.impersonate)

const _title = computed(() => usePage().props.value.title)
const _searching = $ref(false)

const navEntries = computed(() => {

  let entries = []

  if (_user.value?.id) {

    entries = [
      {
        label: 'Klienten',
        key: 'client',
        url: '/clients',

      },
      {
        label: 'Leistungen',
        key: 'service',
        url: '/services',

      },
      {
        label: 'Adressen',
        key: 'address',
        url: '/addresses',
      }
    ]

    if (_modules.value.registrations) {
      entries.push({
        label: 'Anmeldungen',
        key: 'registration',
        url: '/registrations',
      })
    }

    if (_modules.value.protocols) {
      entries.push({
        label: 'Organisation',
        key: 'protocol',
        url: '/protocols',
      })
    }

    if (_modules.value.groups && _user.value.is_group_leader) {
      entries.push({
        label: 'Gruppen',
        key: 'group',
        url: '/groups',
      })
    }


    if (_user.value.is_accountant) {
      entries.push({
        label: 'Rechnungen',
        key: 'invoic',
        url: '/invoicing',
      })
    }

  }

  return entries
})

const _options = computed(() => {

  let entries = [
    {
      label: 'Mitarbeiter',
      icon: 'pi pi-users',
      command: () => Inertia.get('/employees')
    },
  ]

  // {
  //   label: 'Versicherer',
  //   icon: 'pi pi-building',
  //   command: () => Inertia.get('/payers')
  // },

  if (!demo.value) {
    entries.unshift( {
      label: 'Profil',
      icon: 'pi pi-user',
      command: () => Inertia.get('/profile', {}, {preserveState: true, preserveScroll: true})
    })
  }

  if (_user.value.is_admin) {
    entries.push({
      label: 'Administration',
      icon: 'pi pi-cog',
      command: () => Inertia.get('/administration')
    })
  }


  entries.push({
    label: 'Abmelden',
    icon: 'pi pi-sign-out',
    url: '/logout',
  })

  return entries

})


const users_menu = $ref()
const toggleUsersMenu = (event) => {
  users_menu.toggle(event);
}

const _users = computed(() => {

  const users = usePage().props.value.impersonate.users

  if (users) {
    return users.map((user) => ({
      label: user.name,
      url: `/impersonate/${user.id}`
    }))
  }

})

function isCurrent(entry) {
  return usePage().url.value.startsWith(`/${entry.key}`) || usePage().url.value.startsWith(`${entry.url}`)
}

function confirmLogout() {
  if(window.confirm('Wirklich abmelden?')) {
    Inertia.get('/logout')
  }
}

function showSearch() {
  const dialog = useDialog();
  dialog.open(Search, {});
}

</script>

<style lang="scss" scoped>
:deep(.p-menubar) {

  > .p-menubar-button {
    i {
      font-size: 1.3rem;
    }
  }

  &.hide-menu {
    .p-menubar-button {
      display: none;
    }
  }

  .p-menubar-root-list {
    border-radius: .5rem;

    li {
      text-align: center;
      padding: .5rem 0;
    }
  }

}
</style>
