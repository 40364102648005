<template>
  <Button :class="buttonClass"
          v-bind="$attrs"
          :label="label"
          v-tooltip.left="tooltip"
          :icon="`pi pi-${icon}`"
          @click="visit"
          :loading="_loading"/>
</template>

<script setup>

import Button from 'primevue/button'
import {Inertia} from '@inertiajs/inertia'

const props = defineProps({
  label: String,
  tooltip: {type: String, required: false},
  url: {type: String, required: true},
  icon: {type: String, required: true},
  buttonClass: {type: String, default: 'p-button-text p-button-rounded'},
  loading: {type: Boolean, default: false}
})

let _loading = $ref(props.loading)

function visit() {

  _loading = true

  Inertia.get(props.url, {}, {
    preserveState: true,
    onFinish: () => _loading = false
  })
}

</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
